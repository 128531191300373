<template>
  <div class="doctorPage_container" @click="hideMark">
    <div class="doctorPage_sidebar">
      <img :src="getImage('banner')" alt="" />
      <div class="doctorPgae_sidebar_line"></div>
      <div class="doctorPage_custom_area global_full_screen">
        <div
          class="doctorPage_quotes global_container_center"
          :style="getStyle('background')"
        >
          <img :src="require('@/assets/images/meeting/tag.png')" alt="" />
        </div>
        <div
          class="doctorPage_profile global_container_center"
          @click="selectFile"
        >
          <!-- <div class="doctorPage_profile_main"> -->
          <div
            class="doctorPage_profile_block global_container_center"
            :style="getStyle('border-color')"
          >
            <img
              :src="data.doctorHeadImg"
              v-if="data.doctorHeadImg"
              alt=""
              :style="getStyle('border-color')"
            />
            <div
              v-if="isShowMark"
              :style="getStyle('color')"
              class="
                doctorPage_doctor_not_edit
                global_full_screen global_container_center
                doctorPage_profile_mask
              "
            >
              点击编辑
            </div>
          </div>
          <!-- </div> -->
          <div class="doctorPage_profile_border"></div>
        </div>
      </div>
    </div>
    <div class="doctorPage_content">
      <div class="doctorPage_quotes_text">
        <div
          class="doctorPage_quotes_text_block"
          :style="getStyle('border-color')"
        >
          <el-input
            v-model="data.quotation"
            :maxlength="getCfg('quotation', 'template_max')"
            show-word-limit
            type="textarea"
            :placeholder="`请输入参赛语录`"
          />
          <div
            v-if="isShowMark"
            :style="getStyle('color')"
            class="
              doctorPage_doctor_not_edit
              global_full_screen global_container_center
            "
          >
            点击编辑
          </div>
        </div>
      </div>
      <div class="doctorPage_doctor_info">
        <div class="doctorPage_doctor_info_main">
          <div
            class="doctorPage_doctor_info_name"
            :style="getStyle('border-color')"
          >
            <el-input
              v-model="data.doctorName"
              type="textarea"
              :placeholder="`请输入医生姓名`"
            />
            <!-- <div v-if="isShowMark" :style="getStyle('color')" class="doctorPage_doctor_not_edit global_full_screen global_container_center">点击编辑</div> -->
            <div class="global_container_mask"></div>
          </div>
          <div class="doctorPage_doctor_info_detail">
            <div
              class="
                doctorPage_doctor_info_resume_title doctorPage_doctor_info_input
              "
            >
              <div class="doctorPage_doctor_info_input_block">
                <img :src="getImage('doctor_name')" alt="" />
                <el-input
                  v-model="data.resume"
                  :maxLength="getCfg('resume', 'template_max')"
                  :autosize="{ maxRows: 1 }"
                  type="textarea"
                  :placeholder="`请输入职称`"
                />
                <div
                  v-if="isShowMark"
                  :style="getStyle('color')"
                  class="
                    doctorPage_doctor_not_edit
                    global_full_screen global_container_center
                  "
                >
                  点击编辑
                </div>
              </div>
              <div
                class="
                  doctorPage_doctor_info_resume_tip_text
                  global_container_center
                "
              >
                (职称*，包括主任医师、博导、硕导、教授等)
              </div>
            </div>
            <div
              class="
                doctorPage_doctor_info_resume_hospital
                doctorPage_doctor_info_input
              "
            >
              <div class="doctorPage_doctor_info_input_block">
                <img :src="getImage('hospital')" alt="" />
                <el-input
                  v-model="data.doctorHospital"
                  :maxLength="getCfg('doctorHospital', 'template_max')"
                  type="textarea"
                  :placeholder="`请输入医院`"
                />
                <div
                  v-if="isShowMark"
                  :style="getStyle('color')"
                  class="
                    doctorPage_doctor_not_edit
                    global_full_screen global_container_center
                  "
                >
                  点击编辑
                </div>
              </div>
              <div
                class="
                  doctorPage_doctor_info_resume_tip_text
                  global_container_center
                "
              >
                (医院*)
              </div>
            </div>
            <div
              class="
                doctorPage_doctor_info_resume_department
                doctorPage_doctor_info_input
              "
            >
              <div
                class="
                  doctorPage_doctor_info_input_block
                  doctorPage_doctor_info_input_block_other
                "
              >
                <img :src="getImage('hospital')" alt="" />
                <el-input
                  v-model="data.doctorDepartment"
                  :maxLength="getCfg('doctorDepartment', 'template_max')"
                  type="textarea"
                  :placeholder="`请输入科室`"
                />
                <div
                  v-if="isShowMark"
                  :style="getStyle('color')"
                  class="
                    doctorPage_doctor_not_edit
                    global_full_screen global_container_center
                  "
                >
                  点击编辑
                </div>
              </div>
              <div
                class="
                  doctorPage_doctor_info_resume_tip_text
                  global_container_center
                "
              >
                (科室)
              </div>
            </div>
            <div
              class="
                doctorPage_doctor_info_resume_dutuies
                doctorPage_doctor_info_input
              "
            >
              <div
                class="
                  doctorPage_doctor_info_input_block
                  doctorPage_doctor_info_input_block_other
                "
              >
                <img
                  :src="require('@/assets/images/meeting/doctor_name.png')"
                  alt=""
                />
                <el-input
                  v-model="data.resumeJob"
                  :maxLength="getCfg('resumeJob', 'template_max')"
                  type="textarea"
                  :placeholder="`请输入行政职务`"
                />
                <div
                  v-if="isShowMark"
                  :style="getStyle('color')"
                  class="
                    doctorPage_doctor_not_edit
                    global_full_screen global_container_center
                  "
                >
                  点击编辑
                </div>
              </div>
              <div
                class="
                  doctorPage_doctor_info_resume_tip_text
                  global_container_center
                "
              >
                (行政职务)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="doctorPage_doctor_resume">
        <div class="doctorPage_doctor_resume_line">
          <div
            class="doctorPage_doctor_resume_arrow"
            :style="getStyle('border-left-color')"
            v-for="(item, index) in new Array(3).fill(1)"
            :key="index"
          ></div>
          <div
            class="doctorPage_doctor_resume_line_item"
            :style="getStyle('border-top-color')"
          ></div>
          <div
            class="doctorPage_doctor_resume_line_cycle"
            :style="getStyle('background')"
          ></div>
        </div>
        <div class="doctorPage_doctor_resume_ul">
          <el-input
            v-model="data.resumeAcademic"
            :maxLength="getCfg('resumeAcademic', 'template_max')"
            type="textarea"
            :placeholder="`请输入专家简历`"
          ></el-input>
          <div
            v-if="isShowMark"
            :style="getStyle('color')"
            class="
              doctorPage_doctor_not_edit
              global_full_screen global_container_center
            "
          >
            点击编辑
          </div>
        </div>
      </div>
    </div>
    <div
      class="doctorPage_doctor_page_bg global_full_screen"
      v-if="isShowMark"
      @click="hideMark"
    ></div>
    <input
      type="file"
      @change="changeFile"
      :v-if="resetFile"
      v-show="false"
      accept="images/*"
      capture="camera"
      ref="selectFile"
    />
    <ImageCropper
      @receiveData="receiveData"
      ref="imageCropper"
      :CropWidth="150"
      :flexScale="[1, 1]"
      :isShowHeadimgCalibration="true"
      v-if="cropperURL"
    ></ImageCropper>
  </div>
</template>
<script>
import { ElInput } from "element-plus";
import ImageCropper from "@/components/unit/imageCropper";
import weixin from "@/common/weixin";
export default {
  name: "doctorPage",
  props: {
    pageItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    meetInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    meetId: Number,
  },
  components: {
    ElInput,
    ImageCropper,
  },
  data() {
    return {
      isShowMark: true,
      resetFile: true,
      loading: false,
      data: {
        quotation: "",
        doctorHeadImg: "",
        doctorName: "",
        resume: "",
        doctorHospital: "",
        doctorDepartment: "",
        resumeJob: "",
        resumeAcademic: "",
      },
      splitReg: /[,\n]+/g,
      errMsg: ["专家语录", "专家头像"],
      cropperURL: "",
      theme: this.$config.theme,
      keys: [
        "quotation",
        "doctorHeadImg",
        "doctorName",
        "resume",
        "doctorHospital",
        "doctorDepartment",
        "resumeJob",
        "resumeAcademic",
      ],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData(dataKey) {
      dataKey = dataKey || "class_content";
      this.keys.forEach((key, index) => {
        this.data[key] = this.pageItem.content[index][dataKey];
        if (key == "resumeAcademic") {
          this.data[key] = this.$tools.trimEnter(this.data[key]);
        }
      });
    },

    getItem() {
      let key = this.meetInfo?.main_color || "9e004b";
      return this.theme[key];
    },
    getImage(name) {
      let item = this.getItem();
      let prefix = item.name || "";
      prefix = prefix ? `${prefix}_` : "";
      return require(`@/assets/images/meeting/${prefix}${name}.png`);
    },
    getStyle(attr) {
      let item = this.getItem();
      return `${attr}:${item.color}`;
    },
    doEdit() {},
    clearInfo() {
      for (let key in this.data) {
        if (key == "doctorName") continue;
        this.data[key] = "";
      }
    },
    restore() {
      this.initData("default_content");
    },
    hideMark() {
      this.isShowMark = false;
    },
    selectFile() {
      if (this.loading) return;
      // this.$refs.selectFile.click()
      this.loading = true;
      weixin
        .selectFile(1, this.meetId)
        .then((files) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          // this.data.doctorHeadImg = files[0]
          this.cropperURL = files[0];
          console.log(this.files, files[0]);
          this.$nextTick(() => {
            this.$refs.imageCropper.setData(this.cropperURL);
          });
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    getRandomIndex(index) {
      return Math.random() * (index + 1);
    },
    receiveData(data) {
      this.data.doctorHeadImg = data.url;
      this.cropperURL = "";
    },
    changeFile(e) {
      let files = e.target.files;
      this.resetFile = false;
      this.resetFile = true;
      if (files.length == 0) return;
      let data = new FormData();
      this.loading = true;
      for (let index = 0; index < files.length; index++) {
        data.append("file", files[index]);
      }
      this.$axios
        .post(this.$urls.meet.upload, data)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.data.doctorHeadImg = res.url[0];
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    getCfg(key, attr) {
      let index = Object.keys(this.data).indexOf(key);
      return this.pageItem.content[index][attr];
    },
    verifyData() {
      let keys = Object.keys(this.data);
      let content = this.pageItem.content;
      let errcode = 400;
      let resumeAcademic = this.$tools
        .trimEnter(this.data.resumeAcademic)
        .split(this.splitReg);
      let resumeAcademicCount = 0;
      resumeAcademic.forEach((val) => {
        let textCount = Math.ceil(val.length / this.$config.resume.text);
        resumeAcademicCount += textCount;
      });
      if (resumeAcademicCount > this.$config.resume.row) {
        this.$tips.error({
          text: `专家简历最多输入${this.$config.resume.row}行`,
        });
        return errcode;
      }
      for (let index = 0; index < content.length; index++) {
        if (
          content[index].template_type == this.$config.categary.template.image
        ) {
          if (this.data[keys[index]] == "") {
            this.$tips.error({ text: "请上传专家头像" });
            return errcode;
          }
        } else {
          if (
            !(
              content[index].template_min <= this.data[keys[index]].length &&
              this.data[keys[index]].length <= content[index].template_max
            )
          ) {
            this.$tips.error({
              text: `${content[index].remark}字数必须在${content[index].template_min}-${content[index].template_max}之间`,
            });
            return errcode;
          }
        }
      }
      return 200;
    },
    formatData() {
      let data = [];
      for (let key in this.data) {
        if (key == "resumeAcademic") {
          let content = this.$tools
            .trimEnter(this.data[key])
            .replace(this.splitReg, "\n");
          data.push(content);
          continue;
        }
        if (key == "resume") {
          let content = this.data[key].replace(/\n/g, "");
          data.push(content);
          continue;
        }
        data.push(this.data[key]);
      }
      data = data.map((item, index) => {
        return {
          class_content: item,
          update_status: 1,
          id: this.pageItem.content[index].id,
        };
      });
      return { code: this.verifyData(), data };
    },
    getData(callback) {
      let data = this.formatData();
      console.log(data);
      callback(data);
    },
  },
};
</script>
<style>
.doctorPage_container {
  height: 100%;
  width: 100%;
  display: flex;
  background: #fff;
}
.doctorPage_sidebar {
  width: 22.4vw;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.doctorPage_content {
  flex: 1 0 0;
  padding: 0 6vw;
  display: flex;
  flex-direction: column;
}
.doctorPage_sidebar img {
  width: 21.33vw;
  height: 100%;
  position: relative;
  z-index: 2;
}
.doctorPgae_sidebar_line {
  width: 3vw;
  height: 100%;
  box-shadow: 0.5vw 0vw 0.4vw 0.4vw rgba(230, 230, 230, 0.5);
}
.doctorPage_custom_area {
  position: absolute;
  left: 0;
  top: 0;
}
.doctorPage_quotes {
  height: 11.4vw;
  width: 11.4vw;
  background: #9e004b;
  border: 0.8vw solid #fff;
  position: absolute;
  top: 9.33vw;
  right: -5.7vw;
  border-radius: 11.5vw;
  z-index: 10;
}
.doctorPage_quotes img {
  height: 8vw;
  width: auto;
}
.doctorPage_profile {
  height: 32vw;
  width: 32vw;
  /* background:#9e004b; */
  position: absolute;
  top: 25.46vw;
  right: -16vw;
  border-radius: 16vw;
  z-index: 101;
}
.doctorPage_profile_border {
  position: absolute;
  right: 0;
  width: 15.9vw;
  height: 32vw;
  border-right: 0.8vw solid #fff;
  border-top: 0.8vw solid #fff;
  border-bottom: 0.8vw solid #fff;
  border-radius: 0 15.9vw 15.9vw 0;
  box-shadow: 0.5vw 0vw 0.4vw 0.4vw rgba(230, 230, 230, 0.5);
  z-index: 0;
}
.doctorPage_profile_main {
  height: 32vw;
  width: 32vw;
  border-radius: 16vw;
  border: 0.8vw solid #fff;
  position: relative;
  z-index: 40;
}
.doctorPage_profile_block {
  height: 30.4vw;
  width: 30.4vw;
  background: #fff;
  border-radius: 15.2vw;
  border: 1.6vw solid #9e004b;
  position: relative;
  z-index: 40;
}
.doctorPage_profile img {
  height: 24vw;
  width: 24vw;
  border-radius: 12vw;
  position: absolute;
  border: 0.8vw solid #9e004b;
}
.doctorPage_quotes_text {
  height: 23vw;
  padding-top: 7vw;
  width: 100%;
}
.doctorPage_quotes_text_block {
  height: 16vw;
  width: 100%;
  border-radius: 1vw;
  border: 1px solid #9e004b;
  position: relative;
}
.doctorPage_doctor_info {
  /* height:28vw; */
  display: flex;
  /* padding-right: 4vw; */
  justify-content: flex-end;
  margin-top: 4vw;
}
.doctorPage_doctor_info_main {
  height: 100%;
  width: 50vw;
}
.doctorPage_doctor_info_detail {
  margin-top: 1.33vw;
}
.doctorPage_doctor_info_input {
  display: flex;
  align-items: center;
  margin-bottom: 1.33vw;
}
.doctorPage_doctor_info_input_block {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  position: relative;
}
.doctorPage_doctor_info_input_block img {
  height: 2.8vw;
  width: 2.8vw;
  margin-right: 1vw;
}
.doctorPage_doctor_info_input_block_other img {
  visibility: hidden;
}
.doctorPage_doctor_info_detail .el-input,
.doctorPage_doctor_info_detail .el-textarea,
.doctorPage_doctor_info_detail input,
.doctorPage_doctor_info_detail textarea {
  padding: 0;
  margin: 0;
  height: 3.2vw;
  line-height: 3.2vw;
  font-size: 2.8vw !important;
  border: none;
  /* background: transparent; */
  color: #9e004b;
}
.doctorPage_doctor_info_detail input::placeholder,
.doctorPage_doctor_info_detail textarea::placeholder {
  font-size: 2.8vw !important;
  height: 3.6vw;
  line-height: 3.6vw;
}
.doctorPage_doctor_info_detail .el-textarea,
.doctorPage_doctor_info_detail textarea {
  line-height: 3.2vw !important;
  flex: 1 0 0;
  min-height: 3.2vw;
}
.doctorPage_doctor_info_detail input,
.doctorPage_doctor_info_detail textarea {
  /* width:20vw; */
  color: #000;
}
.doctorPage_doctor_info_resume_tip_text {
  color: #d9d9d9;
  font-size: 2.4vw;
  /* flex:1 0 0; */
  display: block;
  height: 100%;
  word-break: break-all;
}
.doctorPage_doctor_info_resume_title .el-textarea,
.doctorPage_doctor_info_resume_title textarea {
  height: 6.4vw !important;
}
.doctorPage_doctor_info_resume_title .doctorPage_doctor_info_resume_tip_text {
  flex: 1.1 0 0;
}
.doctorPage_doctor_info_name {
  border-bottom: 0.66vw solid #9e004b;
  position: relative;
}
.doctorPage_doctor_info_name textarea,
.doctorPage_doctor_info_name .el-textarea {
  /* color:#9e004b; */
  width: 100%;
  padding: 0;
  margin: 0;
  height: 4.8vw;
  line-height: 4.8vw;
  font-size: 4vw !important;
  border: none;
  /* background: transparent;
     */
  /* color:#9e004b; */
}
.doctorPage_quotes_text_block textarea,
.doctorPage_quotes_text_block .el-textarea {
  font-size: 2.8vw !important;
  line-height: 1.3;
  height: 100%;
}
.doctorPage_doctor_resume {
  flex: 1 0 0;
  margin-top: 4vw;
  display: flex;
  flex-direction: column;
  padding: 1vw 0;
}
.doctorPage_doctor_resume_line {
  width: 100%;
  display: flex;
  align-items: center;
}
.doctorPage_doctor_resume_arrow {
  height: 0;
  width: 0;
  border: 1.33vw solid #9e004b;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.doctorPage_doctor_resume_line_item {
  height: 0;
  flex: 1 0 0;
  border-top: 0.33vw dashed #9e004b;
}
.doctorPage_doctor_resume_line_cycle {
  height: 1.33vw;
  width: 1.33vw;
  margin-left: 1vw;
  border-radius: 0.65vw;
  background: #9e004b;
}
.doctorPage_doctor_resume_ul {
  font-size: 2vw;
  margin-top: 2vw;
  position: relative;
  flex: 1 0 0;
}
.doctorPage_doctor_resume_ul li {
  height: 3.33vw;
  line-height: 3.33vw;
  word-break: break-all;
}
.doctorPage_doctor_resume_ul > li::marker {
  color: #9e004b !important;
}
.doctorPage_doctor_resume_ul textarea,
.doctorPage_doctor_resume_ul .el-textarea {
  font-size: 2.8vw !important;
  line-height: 4.2vw !important;
  height: 100%;
}
.doctorPage_doctor_resume_ul textarea::placeholder {
  font-size: 2.8vw !important;
  height: 3.2vw;
  line-height: 3.2vw;
}
.doctorPage_doctor_not_edit {
  color: red;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 102;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 1vw;
  font-size: 2.8vw;
}
.doctorPage_profile_block .doctorPage_doctor_not_edit {
  border-radius: 99999vw;
}
.doctorPage_doctor_page_bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(6, 6, 6, 0.2);
}
</style>