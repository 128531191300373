<template>
  <div class="opacityBg_container global_full_screen global_container_center">
    <slot />
  </div>
</template>
<script>
export default {
  name:"opacityBg"
}
</script>
<style>
.opacityBg_container{
  background:rgba(30,30,30,.5);
  position: fixed;
  left:0;
  top:0;
  z-index:999;
}
.opacityBg_container img{
  height:auto;
  width:50%;
}
</style>