<template>
  <div class="player_container global_full_screen">
    <div class="player_main global_full_screen" >
      <div class="player_module global_full_screen" @touchstart.passive="clickStart" @touchend.passive="clickEnd" @touchmove.passive="clickMove" v-for="(item,index) in data" :key="index" :class="{
        player_module_select:index==curPage,
        ...getAnimaClass(index)
      }" >
        <slot  :citems='item' :cindex='index' v-if="Math.abs(curPage - index) <= 1"/>
      </div>
      <div class="player_footer global_full_screen" @click.stop="stopUpClick" v-if="config.screen.point">
        <div class="player_footer_page_container" v-if="data.length <= 10">
            <div class="player_footer_page_item" v-for="(pageItem,pageIndex) in new Array(data.length)" :key="pageIndex" :class="{player_footer_page_item_select:pageIndex==curPage}" @click.stop="goToPage(pageIndex)"></div>
        </div>
        <div class="player_footer_page_container" v-else>
            <div class="player_footer_page_item"  :key="0" :class="{player_footer_page_item_select:0==curPage}" @click.stop="goToPage(0)"></div>
            <div class="player_footer_page_item"  :key="1" :class="{player_footer_page_item_select:1==curPage}" @click.stop="goToPage(1)"></div>
            <div class="player_footer_page_item_more" v-show="this.curPage > this.config.spacePoint -2">···</div>
            <div class="player_footer_page_item" v-for="(pageIndex) in getPointList()" :key="pageIndex" :class="{player_footer_page_item_select:pageIndex==curPage}" @click.stop="goToPage(pageIndex)"></div>
            <div class="player_footer_page_item_more" v-show="this.curPage < this.data.length - this.config.spacePoint + 1">···</div>
            <div class="player_footer_page_item"  :key="data.length - 2" :class="{player_footer_page_item_select:data.length - 2==curPage}" @click.stop="goToPage(data.length - 2)"></div>
            <div class="player_footer_page_item"  :key="data.length - 1" :class="{player_footer_page_item_select:data.length - 1==curPage}" @click.stop="goToPage(data.length - 1)"></div>
        </div>
      </div>
      <div class="player_control global_full_screen" v-if="config.screen.page" @click.stop="stopUpClick">
        <div class="player_control_button player_control_button_left">
          <div class="player_control_button_block" @click.stop="goToPrepPage" v-show="curPage != 0 || true">
            <el-icon >
              <ArrowLeftBold />
            </el-icon>
          </div>
        </div>
        <div class="player_control_button player_control_button_right">
          <div class="player_control_button_block" @click.stop="goToNextPage" v-show="curPage != data.length - 1 || true">
            <el-icon >
              <ArrowRightBold />
            </el-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {ElIcon} from 'element-plus';
import {ArrowRightBold,ArrowLeftBold} from '@element-plus/icons'
export default {
  name:"player",
  props:{
    data:{
      type:Array,
      default:()=>{
        return []
      }
    },
    cfg:{
      type:Object,
      default:{}
    }
  },
  data(){
    return {
      config:{
        screen:{
          sliding:false, // 是否可以滑动
          page:false, // 是否可以左右翻页
          point:false, // 是否选择点击翻页
        },
        spacePoint:5

        
      },
      curPage:0,
      prepPage:0,
      startPoint:null,
    }
  },
  components:{
    ArrowRightBold,
    ArrowLeftBold,
    ElIcon
  },
  created(){
    this.initConfig()
  },
  methods:{
    initConfig(){
      this.config.screen = {
        ...this.config.screen,
        ...this.cfg
      }
    },
    getAnimaClass(index){
      
        if(this.curPage == this.prepPage) return {};
        // 如果 当前页 大于上一页 则往左滑 
        if(
          (this.curPage > this.prepPage && !(this.curPage == this.data.length -1  && this.prepPage == 0 )) 
          ||  (this.curPage == 0 && this.prepPage == this.data.length - 1) 
        ){
          return {
            player_module_to_left_show:this.curPage == index,
            player_module_to_left_gone:this.prepPage == index
          }
        }
        // 当前页小于上一页，往右滑
        return  {
          player_module_to_right_show:this.curPage == index,
          player_module_to_right_gone:this.prepPage == index
        }

    },
    getPointList(){
      // curpage 小于 4 则 显示 下标 2，3，4
      if(this.curPage < this.config.spacePoint-1) return [2,3,4]
      // curpage > data.list.length - 4 则显示下标 len-5,len-4,len-3
      if(this.curPage > this.data.length - this.config.spacePoint + 1) return [this.data.length - 5,this.data.length - 4,this.data.length - 3]
      return [this.curPage-1,this.curPage,this.curPage+1]
    },
    clickStart(event){
      let client = event.changedTouches[0];
      this.startPoint = client;
    },
    clickMove(event){
      // let client = event.changedTouches[0];
    },
    clickEnd(event){
      if(!this.config.screen.sliding) return;
      let client = event.changedTouches[0];
      // if(client.clientX - this.startPoint.clientX)
      let space = client.clientX - this.startPoint.clientX;
      if(Math.abs(space) < 10){
        this.selectIndex()
        return;
      }
      space > 0 ? this.goToPrepPage() : this.goToNextPage()
      this.startPoint = null;

    },
    selectIndex(){
      this.$emit("select",this.data[this.curPage])
    },
    setPage(page){
      this.prepPage = this.curPage;
      this.curPage = page;
    },
    stopUpClick(event){
      console.log(111)
      event.preventDefault()
      event.cancelBubble = true;
    },
    goToPrepPage(){
      if(this.curPage == 0){
        this.setPage(this.data.length -1);
        return;
      }
      this.setPage(this.curPage - 1)
    },
    goToNextPage(){
      if(this.curPage == this.data.length - 1){
        this.setPage(0)
        return;
      }
      this.setPage(this.curPage + 1)
    },
    goToPage(index){
      this.setPage(index)
    }
  }
}
</script>
<style scoped>
.player_container{
  position: relative;
  overflow: hidden;
}
.player_module{
  position: absolute;
  left:0;
  top:0;
  z-index:700;
  overflow: hidden;
}

.player_module_select{
  z-index:900
}
/* .player_module img{
  height:100%;
  width:auto;
  max-width:100%;
} */
.player_control{
  position: absolute;
  left:0;
  top:0
}
.player_control_button{
  font-size:4vw;
  color:#fff;
  height:100%;
  position: absolute;
  top:0;
  z-index:50;
  display: flex;
  align-items: center;
  justify-content: center;
}
.player_control_button_left{
  left:4vw;
}
.player_control_button_right{
  right:4vw;
}
.player_control_button_block{
  height:6vw;
  width:6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(9,9,9,.5);
  border-radius: 50%;
}
.player_footer{
  position: absolute;
  bottom:0;
  left:0;
  width:100%;
  height:4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:1000;
}
.player_footer_page_container{
  display: flex;
  align-items: center;
}
.player_footer_page_item{
  width:2vw;
  height:2vw;
  background:#000;
  border-radius:1vw;
  margin:1vw;
}
.player_footer_page_item_more{
  width:3vw;
  height:2vw;
  line-height: 2vw;
}
.player_footer_page_item_select{
  background: #a74981;
}
.player_module_to_right_show{
  z-index:900;
  animation-timing-function: linear;
  animation:_right_show .5s;
  -webkit-animation:_right_show .5s; /* Safari 和 Chrome */
}
.player_module_to_right_gone{
  z-index:800;
  animation-timing-function: linear;
  animation:_right_gone .5s;
  -webkit-animation:_right_gone .5s; /* Safari 和 Chrome */
}
.player_module_to_left_show{
  z-index:900;
  animation-timing-function: linear;
  animation:_left_show .5s;
  -webkit-animation:_left_show .5s; /* Safari 和 Chrome */
}
.player_module_to_left_gone{
  z-index:800;
  animation-timing-function: linear;
  animation:_left_gone .5s;
  -webkit-animation:_left_gone .5s; /* Safari 和 Chrome */
}
@keyframes _left_gone {
  0%{
    left:0;
  } 
  100%{
    left:-100%;
  } 
}
@keyframes _left_show {
  0%{
    left:100%;
  } 
  100%{
    left:0%;
  } 
}
@keyframes _right_gone {
  0%{
    left:0;
  } 
  100%{
    left:100%;
  } 
}
@keyframes _right_show {
  0%{
    left:-100%;
  } 
  100%{
    left:0%;
  } 
}
</style>